import React from 'react';
import './App.css';
import Calendar from './components/Calendar';
function App() {
  return (
    <div>
        <Calendar />
    </div>
  );
}

export default App;
